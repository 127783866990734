import React, { Component } from "react";
import $ from "jquery";
import LeftSidebar from "./Leftsidebar";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    sendMail = () => {
        // code fragment
        // the form id is myForm
        $('#myForm').on('submit', function (event) {
            event.preventDefault(); // prevent reload
            var formData = new FormData(this);
            formData.append('service_id', 'service_wp6i3p1');
            formData.append('template_id', 'template_dkc4xla');
            formData.append('user_id', 'user_28q4S3G0VvAcT8hAgndqQ');
            $.ajax('https://api.emailjs.com/api/v1.0/email/send-form', {
                type: 'POST',
                data: formData,
                contentType: false, // auto-detection
                processData: false // no need to parse formData to string
            }).done(function () {
                window.open('/thank-you', '_self')
            }).fail(function (error) {
                alert('Oops... ' + JSON.stringify(error));
            });
        });
        // code fragment
    }
    render() {
        return (
            <span>
                <LeftSidebar />
                <section className="contact-us">
                    <div className="background-image d-flex justify-content-start align-items-center">
                        <h1 className="main-contact-text text-light d-flex justify-content-center align-items-start flex-column pb-5" style={{ width: "50%", height: "50%", backgroundImage: "linear-gradient(128deg, rgba(9, 33, 39, 0.79), rgba(8, 23, 27, .799))" }}>
                            We are all ears<br />
                            when you speak
                        </h1>
                    </div>
                </section>
                <section style={{ paddingTop: 50, paddingBottom: 100 }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mobile-dif-content">
                                <div className="col-md-12 pb-5">
                                    <h4 className="h4 font-weight-bold text-primary text-left">Contact us via</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="font-weight-bold text-warning h6 text-left">India</div>
                                    <hr className="mt-0" />
                                    <div className="d-flex justify-content-start align-items-center mb-3">
                                        <i className="fa fa-phone" aria-hidden="true"></i><p className="text-black-50 ml-3 m-0">+91 9846124213</p>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mb-3">
                                        <i className="fa fa-envelope-o" aria-hidden="true"></i><p className="text-black-50 ml-3 m-0">info@thetechelements.com</p>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-start mb-3">
                                        <i className="fa fa-map-marker ml-1 mt-1" aria-hidden="true"></i>
                                        <p className="text-black-50 text-left ml-3 m-0">Madathikunnel Complex,<br />
                                            Kathrikadavu Junction, Kaloor P.O - 682017,<br />
                                            Ernakulam, Kerala, India
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md-12 pb-5 mt-5">
                                    <h4 className="h4 font-weight-bold text-primary text-left">Representative Office</h4>
                                    <hr className="mt-0" />
                                    <div className="font-weight-bold text-dark d-flex align-items-center justify-content-center-sm">
                                        Australia&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        {/* India&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp; */}
                                        Italy&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        Germany&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        Netherlands&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                    </div>
                                    <div className="font-weight-bold text-dark d-flex align-items-center justify-content-center-sm">
                                        Singapore&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        UK&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        USA&nbsp;<i className="fa fa-circle" aria-hidden="true" style={{ fontSize: 7 }}></i>&nbsp;
                                        Korea
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h4 className="h4 text-primary font-weight-bold" style={{ marginBottom: 30 }}>
                                    Or use the below form
                                </h4>
                                <form id="myForm">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            required={true}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="company"
                                            placeholder="Company*"
                                            required={true}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="reply_to"
                                            placeholder="Official Email*"
                                            required={true}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="number"
                                            placeholder="+00"
                                            required={true}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <select required={true} className="form-control" name="subject">
                                            <option value="subject not selected">Subject</option>
                                            <option value="Request Demo">Request Demo</option>
                                            <option value="Careers">Careers</option>
                                            <option value="Partner with us">Partner with us</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>MESSAGE</label>
                                        <textarea className="form-control" name="message" rows="6" />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-success" type="submit" onClick={this.sendMail}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </span>
        )
    }
}
export default Contacts;