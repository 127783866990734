import React, { Component } from 'react';
import windowSize from 'react-window-size';
import AOS from 'aos';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { ReactSVG } from 'react-svg';
import { Parallax } from 'react-parallax';
import {
    Segment,
    Sidebar,
} from 'semantic-ui-react';

import LeftSidebar from './Leftsidebar';
import RightSidebar from './Rightsidebar';

// why?
import Why from './svg/why.svg';

// icons
import RoundOuterline from './svg/round-outer-line.svg';
import Search from './svg/search.svg';
import agreement from './svg/agreement.svg';
import sales from './svg/sales.svg';
import ladder from './svg/ladder.svg';
import noteCase from './svg/note-case.svg';
import team from './svg/team.svg';
import engineer from './svg/engineer.svg';
import toTop from './svg/toTop.svg';
import close from './svg/close.svg';
import facebook from './svg/facebook.svg';
import linkedin from './svg/linkedin.svg';
import instagram from './svg/instagram.svg';
import reactLogo from './svg/react.svg';

// video
import ReactPlayer from 'react-player'
import Videofile from './video/video.mp4';

// d
// import PrimeLogo from './images/primeMarine.png';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: 'overlay',
            direction: 'top',
            dimmed: false,
            visible: false,
            showme: true
        }
    }




    scrollToTop = () => {
        scroll.scrollToTop();
    }



    Why = () => {
        scroller.scrollTo('why-te', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
        this.closeDrawer()
    }
    Services = () => {
        scroller.scrollTo('services', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
        this.closeDrawer()
    }
    Career = () => {
        scroller.scrollTo('career', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',

        })
        this.closeDrawer()
    }
    Contact = () => {
        scroller.scrollTo('contact', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
        this.closeDrawer()
    }
    Product = () => {
        scroller.scrollTo('product', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
        this.closeDrawer()
    }

    openDrawer = () =>
        this.setState({
            visible: true
        })
    closeDrawer = () =>
        this.setState({
            visible: false
        })

    render() {
        AOS.init();
        return (<>
            <Sidebar
                as={Segment}
                animation={this.state.animation}
                direction={this.state.direction}
                visible={this.state.visible}
                style={{ height: "100vh" }}
                className="bg-dark"
            >
                <div className="close-btn d-flex align-items-center">
                    <span className="text-light">close</span>&nbsp;&nbsp;<ReactSVG className="float-right" src={close} onClick={this.closeDrawer} />
                </div>
                <section className="navigation-root">
                    <div className="container">
                        <div className="text-light simple-word  simple-word-with-line" style={{ paddingTop: 60 }}>make it happen!</div>
                        <div onClick={this.Why} className="main-text">Why?</div>
                        <div onClick={this.Services} className="main-text">Services</div>
                        <div onClick={this.Product} className="main-text">Products</div>
                        <div onClick={this.Career} className="main-text">Career</div>
                        <div onClick={this.Contact} className="main-text">Get in Touch!</div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="social-media d-flex"><ReactSVG src={facebook} />&nbsp;&nbsp;<ReactSVG src={linkedin} />&nbsp;&nbsp;<ReactSVG src={instagram} /></div>
                </section>
            </Sidebar>
            <LeftSidebar />
            <RightSidebar onClick={this.openDrawer} />

            <section className="tech-background-image" >
                {/* <img alt="banner" src={Banner} style={{ width: '100%' }} /> */}
            </section>
            <section name='why-te' className='about'>
                <div className="container">
                    <div className="row flex-reverse-sm">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={Search} />
                                    <div className="h6 font-weight-bold">TRANSPARENCY</div>
                                    <p className=" sub-text">Security in knowing that you never have to question where you're at or what's happening in the development process.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={agreement} />
                                    <div className="h6 font-weight-bold">LONG TERM PARTNERSHIP</div>
                                    <p className=" sub-text">Gain stability with a partner who believes in long-term relationships and views it as a fundamental value.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={sales} />
                                    <div className="h6 font-weight-bold">ON THE SAME PAGE</div>
                                    <p className=" sub-text">Work with teams that have your business goals in mind. Each step is a step towards improving your overall business processes.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={ladder} />
                                    <div className="h6 font-weight-bold">CONSTANT IMPROVEMENT</div>
                                    <p className=" sub-text">Regular internal seminars, training, and workshops contribute to the continuous improvement of Computools's engineers and analysts.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={noteCase} />
                                    <div className="h6 font-weight-bold">INSTANT SCALING OF IN HOUSE TEAMS</div>
                                    <p className=" sub-text">Meet project deadlines through the instant scaling of an in-house digital team or adding professionals as needed.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={team} />
                                    <div className="h6 font-weight-bold">FULL CONTROL OVER TEAM MANAGEMENT</div>
                                    <p className=" sub-text">Get a dedicated team fully integrated into your company&#39;s processes, involved in your project and under your management.</p>
                                </div>
                                <div className="form-group col-lg-6 col-md-4">
                                    <ReactSVG className="icon" src={engineer} />
                                    <div className="h6 font-weight-bold">PROFESSIONAL ENGINEERS WITH NICHE SKILLS</div>
                                    <p className=" sub-text">Utilize experienced engineers with the skills to deliver quality results on time and on budget.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ReactSVG className="why-image" src={Why} />
                            <div className="video-mask">
                                <ReactPlayer width='100%' height='auto' playing loop url={Videofile} />
                                <div className="video-blend" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Parallax
                blur={3}
                bgImage={require('./images/service.png')}
                bgImageAlt="the cat"
                strength={200}
            >
                <section name="services" className="services" onFocus={this.test}>
                    <div className="d-flex flex-column justify-content-center align-items-center services-content">
                        <div className="container" style={{ padding: "0px 40px 80px 40px", borderRadius: 10, backgroundColor: '#000000a6' }}>
                            <div className="main-text text-light simple-word simple-word-with-line mt-3">Services</div>
                            <div className="col-lg-12">
                                <div className="row" style={{ marginTop: 30 }}>
                                    <div className="col-lg-3 col-md-12 form-group">
                                        <div className="h3 text-light">Consulting</div>
                                        <p className="text-light" style={{ marginTop: 20 }}>Create the right IT Strategy with the best return on investment in technology</p>
                                    </div>
                                    <div className="col-lg-3 col-md-12 form-group">
                                        <div className="h3 text-light">Design</div>
                                        <p className="text-light" style={{ marginTop: 20 }}>Apply and design a valuable user experience for your digital products</p>
                                    </div>
                                    <div className="col-lg-3 col-md-12 form-group">
                                        <div className="h3 text-light">Engineering</div>
                                        <p className="text-light" style={{ marginTop: 20 }}>Engineer next generation software solutions for your business</p>
                                    </div>
                                    <div className="col-lg-3 col-md-12 form-group">
                                        <div className="h3 text-light"> Optimization</div>
                                        <p className="text-light" style={{ marginTop: 20 }}>Optimize your business systems with streamlined methods for increasing revenue and decreasing costs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>
            <Parallax
                blur={3}
                bgImage={require('./images/productBanner.jpg')}
                bgImageAlt="the cat"
                strength={200}
            >
                <section name="product" className="product">
                    <div className="d-flex flex-column justify-content-center align-items-center product-content" >
                        <div className="container" style={{ padding: "0px 40px 80px 40px", borderRadius: 10, backgroundColor: 'rgb(0 0 0 / 74%)' }}>
                            <div className="main-text text-light simple-word simple-word-with-line mt-3">Products</div>
                            <div className="col-lg-12">
                                <div className="row justify-content-center" style={{ marginTop: 30 }}>
                                    <div className="col-md-8">
                                        <div className="h3 text-light text-center">PRIME Marine</div>
                                        <p className="text-white text-center" style={{ marginTop: 20, opacity: .6 }}>PRIME Marine is a product developed by Tech Elements which specifically focuses on the challenges faced by the maritime industry. Our product portfolio includes</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 form-group col-lg-4">
                                    <div className="border shadow radius-10 p-4">
                                        <div className="h3 font-weight-bold text-light text-left">PMS</div>
                                        <p className="text-light text-left" style={{ marginTop: 20 }}>To help ship owners and operators manage vessel maintenance</p>
                                    </div>
                                </div>
                                <div className="col-md-12 form-group col-lg-4">
                                    <div className="border shadow radius-10 p-4">
                                        <div className="h3 font-weight-bold text-light text-left">Procurement</div>
                                        <p className="text-light text-left" style={{ marginTop: 20 }}>To optimize purchasing, logistics and accounting activities</p>
                                    </div>
                                </div>
                                <div className="col-md-12 form-group col-lg-4">
                                    <div className="border shadow radius-10 p-4">
                                        <div className="h3 font-weight-bold text-light text-left">QHSE</div>
                                        <p className="text-light text-left" style={{ marginTop: 20 }}>To manage Quality, Health, Safety and Environment aspects for vessel operators</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <span onClick={() => window.open('https://www.primemarine.com/', '_prime')} className="btn btn-outline-primary mt-3">Explore More</span>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>
            <section name="career" data-aos="slide-right" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once={true} className='career'>
                <div className="container career-content">
                    <div className="text-light simple-word simple-word-with-line" style={{ paddingTop: 60 }}>career</div>
                    <div className="main-text text-light">
                        Ready to join the team?
                    </div>
                    <div className="h3 text-light" style={{ marginTop: 50 }}>
                        Tech Elements is hiring for a variety of positions. If you want to push the boundaries of human intelligence, get in touch.
                    </div>
                    <div className="h3 text-light font-weight-bold" style={{ marginTop: 50 }}>
                        Front-end Developer
                    </div>
                    <div className="h4 text-light d-flex align-items-center justify-content-between" style={{ borderBottom: '1px solid #bbb' }}>
                        <span className="d-flex align-items-center sm-font">
                            <ReactSVG className="icon" src={reactLogo} />&nbsp;&nbsp;React Developer
                        </span>
                        <span onClick={() => window.open('/Contacts', '_Contact')} className="btn btn-sm btn-light">Apply Now</span>
                    </div>
                    <div className="h3 text-light font-weight-bold" style={{ marginTop: 50 }}>
                        Back-end Developer
                    </div>
                    <div className="h4 text-light d-flex align-items-center justify-content-between" style={{ borderBottom: '1px solid #bbb' }}>
                        <span className="d-flex align-items-center sm-font">
                            <b className="d-flex align-items-center" style={{ width: 50, height: 50 }}>.NET</b>&nbsp;&nbsp;Dot Net Core Developer
                        </span>
                        <button onClick={() => window.open('/Contacts', '_Contact')} className="btn btn-sm btn-light">Apply Now</button>
                    </div>
                </div>
            </section>
            <section data-aos="slide-right" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once={true} className="contact">
                <div className="container contact-head pt-5">
                    <div className="text-light simple-word  simple-word-with-line" >make it happen!</div>
                    <div className="main-text text-light">
                        let's build something awesome!
                    </div>
                    <div className="text-light font-weight-bold simple-word simple-word-with-line" style={{ paddingTop: 60 }}>Contact</div>
                    <div className="text-primary contact-content" style={{ paddingTop: 10 }}>info@thetechelements.com</div>
                    <div className="text-primary contact-content" style={{ paddingTop: 10 }}>+91&nbsp;&nbsp;85930&nbsp;&nbsp;22203</div>
                    <div className="text-light contact-content" style={{ paddingTop: 10 }}>3rd floor, madhathilkunnel complex, kalloor kochi - 682018</div>
                    <div className="toTop" >
                        <ReactSVG onClick={this.scrollToTop} src={toTop} />
                    </div>
                </div>
            </section>
            {/* <section name="contact" data-aos="slide-right" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once={true} className="contact">
                    <div className="container">
                       
                       
                    </div>
                </section> */}
        </>);
    }
}

export default windowSize(Home);




