import React, { Component } from 'react';
import logo from './images/TELogo.png'
class LeftSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <div className="sidebar d-flex flex-column">
                    <a className="align-self-center" style={{ marginTop: 20 }} href="/">
                        <img className="tech-logo" style={{ filter: 'drop-shadow(0px 0px 1px #fff)' }} alt="main_logo" src={logo} />
                    </a>
                    <p className="align-self-center credits nowrap contrast hide-on-sm">© 2019-2022&nbsp;&nbsp;Tech Elements - Kochi</p>
                </div>
            </>
        );
    }
}

export default LeftSidebar;