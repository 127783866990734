import React, { Component } from 'react';
import LeftSidebar from './Leftsidebar';
import { ReactSVG } from 'react-svg';
import { Tab, Accordion } from 'semantic-ui-react'

// icons
import RoundOuterline from './svg/round-outer-line.svg';
import arrowDown from './svg/arrow_down.svg';
import arrowUp from './svg/arrow_up.svg';



class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: -1,
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })


    }

    render() {
        const { activeIndex } = this.state
        return (<>
            <LeftSidebar />
            <section className="serviceContent">
                <ReactSVG className="c-circle" src={RoundOuterline} />
                <div className="container">
                    <div className="main-text">
                        Services
                    </div>
                    <Tab menu={{ secondary: true, pointing: true }} style={{ marginTop: 30 }} panes={
                        [
                            {
                                menuItem: 'Consulting',
                                render: () => <Tab.Pane attached={false}>
                                    <div className="h5 font-weight-bold form-group">The IT Partner in Your Business</div>
                                    <p>A team of talented IT engineers assigned with developing and supporting digital products for your business.</p>
                                    <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Consulting</div>
                                    <p>Create the optimal IT Strategy with the best return on investment from technology.</p>
                                    <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>The TE Method</div>
                                    <p>TE guides you with strategic advice on technology, your business goals and operations to adopt the optimal IT Strategy with the best return on technology investment.</p>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 0}
                                            index={0}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Defining Goals<ReactSVG src={activeIndex === 0 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 0} className="service-body" >
                                            <div className="row">
                                                <div className="col-md-1 d-flex justify-content-end">1.</div>
                                                <div className="col-md-11">
                                                    <p>Researching the main idea of ​​the project.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Exploring the high-level main goals of the project.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end">3.</div>
                                                <div className="col-md-11">
                                                    <p>Identifying project stakeholders, including the hidden ones. Hidden stakeholders can be participants of a project interested in a client’s business who are not clearly represented to the team, that can be state and international regulatory authorities, public organizations, non-obvious participants influencing users of the service directly or indirectly, ​including others.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Identifying stakeholders’ goals.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Adjustment of high-level goals while taking into account the stakeholders&#39; goals. In order to minimize differences in project vision and move in the right direction, it is critical for the team to clearly understand and share the client’s goals.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Determining all of the client&#39;s primary high-level expectations, which are known at this stage.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Vision synchronization of this unit with the client.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                <div className="col-md-11">
                                                    <p>Identifying all User Roles and key User Stores.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                <div className="col-md-11">
                                                    <p>Gaining access to industry insights from TE experienced professionals to place you at a competitive advantage and minimize the risk of costly errors.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >8.</div>
                                                <div className="col-md-11">
                                                    <p>Building strong communication to ensure that everyone is on the same page that is essential to success. This is done by forming a glossary of project terms.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>TE prepares agendas before each meeting and records results in a summary. This summary is shared to all team members in a general channel. This allows the team and client to save time and minimizes human factor and the negative impact of it.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Commonly - accepted terminology in the industry reduces miscommunication.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >9.</div>
                                                <div className="col-md-11">
                                                    <p>Forming a complete list of roles.</p>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 1}
                                            index={1}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group" >Analysis &amp; Exploration<ReactSVG src={activeIndex === 1 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 1} className="service-body">
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Creating user stories based on company knowledge and experience.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Researching competitors&#39; existing solutions and technologies.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>Researching the groups of client needs to clarify the best user experience.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Analyzing data, ready-made solutions on the market, their real (and not declared) pros and cons as well as who use them.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Decomposing user stories to user roles based on the results of research. Visualizing a tree of user roles, stories and cases via MindMap.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                <div className="col-md-11">
                                                    <p>Defining main restrictions and providing solutions for them.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>system licensing and other legal requirements.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>corporate policies.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>localizations.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>peak number of users online.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>permissible downtime.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to fail-safe feature.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to scalability based on potential customer base growth.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to system expansion speed.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>modularity.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>extensibility.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>code reusability.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to delivery speed.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>release delivery speed.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>speed of certain groups of fixes.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>replacement speed of certain content or logic (often when using the admin panel or CMS).</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to security level</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>system access security level.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>level of software protection from solution-vendor (operating systems, application servers, VM, etc.).</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>DDoS Protection.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>Data encryption in storage.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>User data protection.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                <div className="col-md-11">
                                                                    <p>Phishing Protection.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                                <div className="col-md-11">
                                                                    <p>protection against unique content copying.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                                <div className="col-md-11">
                                                                    <p>security analysis of third-party dependencies.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >9.</div>
                                                                <div className="col-md-11">
                                                                    <p>other additional options.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>sizes and types of information that will need to be stored.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>repository security requirements.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>system response speed: average and minimum.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>common.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>at certain critical points of the system.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>list of end-user devices (device types, operating systems, input device screen extensions, etc.).</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>server device list of client&#39;s business to which transfer may be required.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>requirements to external protocols, formats and interfaces.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>reliability requirements.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>logging.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>backups.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>system support features.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>logging.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>testing (automatic, manual). It includes both coverage and speed of testing cycles.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>domain specific restrictions and requirements.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>general UI and UX design requirement.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                <div className="col-md-11">
                                                    <p>Identifying the main transitive requirements, such as migration, learning complexity and speed.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >8.</div>
                                                <div className="col-md-11">
                                                    <p>Identifying the main risks.</p>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                </Tab.Pane >,
                            },
                            {
                                menuItem: 'Design',
                                render: () => <Tab.Pane attached={false}>
                                    <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>TE Approach</div>
                                    <p>TE designs ecosystems that allow for building scalable and productive user-centric digital products.</p>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 3}
                                            index={3}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">High-Level Architecture<ReactSVG src={activeIndex === 3 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 3} className="service-body">
                                            <p>Selecting approaches and a key development stack based on analytics.<b> First of all, ready-made solutions are taken into account, both those available on the market and those in, in-house development.</b> These allow TE to develop in the best way, reduce the chance of error emerging, improve the quality of product requirements and the product itself, and obtain a basic coverage of
                                                non-functional and transitive requirements. In this case, the cost of ready-made solutions play an important role.</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Selection of basic architectural patterns and tools:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>monolithic application or micro service architecture.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>vertical and horizontal scaling schemes that impose a number of requirements for further development.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>basic patterns of module writing.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>physical and logical placing schemes for key modules.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>critical external integrations.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>critical caches and queues, types of used data stores, such as relational or document-oriented databases.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p style={{ marginTop: 10 }}>At this stage, no final decisions are made since these aspects can be updated during the whole architecture forming stage.</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Forming an application stack taking into account:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>restrictions and ready-made solutions support.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>reliability of solutions based on vendor support and future expansion of platform capabilities.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>the speed with which the solution’s vendor closes security issues.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>cases of using open source solutions without vendor support issues. (Though, there are additional risks in that, they can be solved by TE’s team).</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>threshold of entry and prevalence of the solution. Often it also influences the average cost of development.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>the cost of the necessary infrastructure, which includes hosting and VPC prices, additional licence costs, etc.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>development speed and quality while using this stack.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>count of tools for development and debugging.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 4}
                                            index={4}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Interactive Prototypes &amp; Specification<ReactSVG src={activeIndex === 4 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 4} className="service-body">
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Development of detailed specification that covers all user stories. Specification sections can be grouped by specific scenarios depending on the industry and application type. The functional is described in detail but not redundantly; acceptance criteria and the restrictions imposed on them. Such as speed security and further are specified.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>forming backlog from the raw user story/cases. Stories can be detailed as part of the initial specification, but final detailing develops as a part of sprint planning.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>a fully described story contains:</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>a detailed description.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>functional requirements.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>non-functional requirements.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>acceptance criteria.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>a breakdown into task - describing, which needs to be done to implement the task with decomposition by type of work.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>additional.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>an activity diagram from the BA perspective.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>a process diagram from the BA perspective.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>the specification is being finalized during the entire project life cycle.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Development of a prototype in parallel with specification..</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>Forming Definition of Done..</p>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 5}
                                            index={5}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Build Development Process<ReactSVG src={activeIndex === 5 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 5} className="service-body">
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Forming the development process in accordance with the identified requirements and interests of stakeholders. The main frameworks are:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Scrum is used for the active development phase of medium and large projects, or small projects from where the client does not have clear 100% insight into. It is also used during the final phases of broad functionalities in ready-made projects.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Kanban is used during the support and optimization phase of an already developed project.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Waterfall is used for small projects (up to 2-3 months) or blocks of functionality in ready-made projects with 100% vision from the client and the team.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Calculating the number of project teams in case a project needs more than 20 project participants.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>Forming process configuration.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Discussing the necessary roles of the project participants. The list of roles and their main responsibilities are:.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Product Owner</div>
                                                            <p>forming of the high-level goals and objectives, providing the budget, management of project size and time, approving the task scope. This role is often performed on the client side.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Project Manager</div>
                                                            <p>forming and control of the process, control of agreements, management of the budget provided by a Product Owner, team coordination, reporting to and supporting of the Product Owner, solving organizational issues and risk management.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Business Analytic</div>
                                                            <p>detailing of the goals provided by the Product Owner, development of the requirements, project analysis, analysis of competitors&#39; technical solutions, consult the Product Owner, development of project documentation, management of the project scope, is required to give explanations to the team. </p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>System Architect</div>
                                                            <p>development of system architecture, high-level decomposition, stack choice, ready-made solutions and basic tool choice, analysis of the system’s technical component, technical risk management, technical consulting for the Product Owner, Business Analyst and Team Lead.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>UX Designer</div>
                                                            <p>development of project user experience, user experience analysis, prototyping, arrangement of UX test plans, development of basic UI requirements.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>UI Designer</div>
                                                            <p>development of project design, development of style guide, development of the UI toolkit, page wireframe detailing, development of animations and adaptive views.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Team lead</div>
                                                            <p>configuration of development processes, coordination of the development team, technical decomposition, task distribution, code review, solving of complex and difficult situations during development and development of key system modules.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Software Engineer</div>
                                                            <p>estimation and implementation of development tasks, that includes; the writing of Unit and integration tests and working with documentation.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>DevOps</div>
                                                            <p>configuration of used tools, configuration of all environment types (includes production), creation of CI and CD processes, support testing process (includes auto tests), platform work control and monitoring and configuration of third-party systems.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>QA lead</div>
                                                            <p>testing process configuration, verification of build quality before uploading to demo or production environments, testing plan development, QA team coordination, detection of bugs in design and specification, development of key testing documentation types, system security testing.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>QA</div>
                                                            <p>manual testing, feature aspect testing, regression testing, system security testing, load testing, bug reports, development of test cases and checklists, improvement suggestions.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>Auto QA</div>
                                                            <p>auto, e2e, api and load test estimations and implementation.</p>
                                                            <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>dfhsdhnsdgnsgdn</div>
                                                            <p>sdghnjsdgngsdn</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Forming of work format:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Points of getting approvals from the client’s team.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>The client&#39;s role in processes (it&#39;s usually the Product Owner) - The client is considered as a team member and a process participant by the team.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Product Owner involvement into meetings:</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>intro meeting (optional).</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>daily meeting (optional).</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>meeting with Business Analyst.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>planning - if the client cannot attend the meeting, the project manager agrees upon the scope.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>retrospective (optional).</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                <div className="col-md-11">
                                                                    <p>demo.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Reporting points. The more the Product Owner is involved in the project, there will be fewer mistakes caused by inconsistency. Written reports are given every day by default and meetings with the project manager are set up whenever it&#39;s possible.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Work format synchronisation with Business Analyst.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Demo formats and time synchronisation with Product Owner.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Configuration of the list and volume of necessary internal meetings.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Definition of Done&#39; creation.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Configuration the technological processes and interactions between roles. It is based on the company&#39;s overall process which is optimized for the current project while taking into account the requirements of team size.</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Definition of Done&#39; creation.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>task flow.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>task description.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>bug reports</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>design handover</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>API docs formats</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                <div className="col-md-11">
                                                                    <p>etc</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Developers&#39; testing processes:</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>unit tests planned coverage</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>integration tests</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>Git flow - by default feature branch:</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>branch flow - naming, releases etc.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>automation of code analysis</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>code review</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>pull request</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>QA flow - includes testing from the designer:</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>smoke testing</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>aspect testing</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>designer review</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>regression testing:</p>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                        <div className="col-md-11">
                                                                            <p>manual</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                        <div className="col-md-11">
                                                                            <p>autotest</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>release candidate testing:</p>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                        <div className="col-md-11">
                                                                            <p>manual</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                        <div className="col-md-11">
                                                                            <p>autotest</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                <div className="col-md-11">
                                                                    <p>CI/CD - deploys flow</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    {/* <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>gfjdgsdfghdfh</p>
                                                        </div>
                                                    </div> */}
                                </Tab.Pane>,
                            },
                            {
                                menuItem: 'Engineering',
                                render: () => <Tab.Pane attached={false}>
                                    <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>The TE Approach</div>
                                    <p>TE’s team will assist you at any phase of software engineering to build a quality product and speed up the achievement of your goals.</p>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 6}
                                            index={6}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Stage 1 - Build The Team<ReactSVG src={activeIndex === 6 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 6} className="service-body">
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Making the initial scope based on the specification and estimate.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Сalculating the optimal number of specialists for each role based on expectations for the completion date, taking into account the non-linear productivity increase with a linear hour number increase.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>The team’s core consists of professionals who started working on the project from its early stages, i.e. Business Analytic, Architect, Team Lead, Project Manager, UX designer.Ordinary executors are pre-assembled on the project manager&#39;s team.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Executors are added according to the following criteria:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>level of technical skills</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>knowledge in the domain</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Depending on the project specifics and the number of executors, necessary key roles are involved.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                <div className="col-md-11">
                                                    <p>If the required number of executors is higher than the allowable level within the same team, additional teams are included in the project.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                <div className="col-md-11">
                                                    <p>An intro meeting.</p>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 7}
                                            index={7}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Stage 2 – Development<ReactSVG src={activeIndex === 7 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 7} className="service-body">
                                            <p>Carrying out the iterative development phase based on the chosen management framework. It includes iterations based on these next steps:</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>Specifying the story, including acceptance criteria by the Business Analyst.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Decomposing the story by the Team Lead and Business Analyst. Consult with an Architect if necessary.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>Distribution of tasks by the Project Manager and Team Lead.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Preparing the UX\UI design, and if necessary, using these editors:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Figma</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Sketch</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>Photoshop</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Consult with the Business Analyst if tasks aren’t detailed enough.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                <div className="col-md-11">
                                                    <p>Estimation tasks and approvement by Team Lead.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                <div className="col-md-11">
                                                    <p>Task development in accordance with git flow. By default it is feature/branch that includes test development. Possible methodologies are:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >a.</div>
                                                        <div className="col-md-11">
                                                            <p>Domain driven development (DDD) - used for architecture of logic and code. It is possible to use alternatives in a number of cases.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >b.</div>
                                                        <div className="col-md-11">
                                                            <p>Test Driven Development (TDD) - used for implementation of the main logic in code.</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>Creating pull requests with static code analysis using next tools (depending on technology):</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>Code review and fixes</p>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >a.</div>
                                                                        <div className="col-md-11">
                                                                            <p>goals</p>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>identifying logical errors in business rules</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>identifying potential bugs and unwanted side effects</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>removing duplicates</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>elimination of complicated implementations</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>keeping unified system structure</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>keeping unified system style</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>detecting errors missed by code analyzers</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >c.</div>
                                                                        <div className="col-md-11">
                                                                            <p>types</p>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>cross team review – by all participants of the module development including the Team lead. Provides higher performance but takes significantly more time</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>Team lead review</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>Security review</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginTop: 10 }}>
                                                                        <div className="col-md-1 d-flex justify-content-end" >d.</div>
                                                                        <div className="col-md-11">
                                                                            <p>tools</p>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>GitHub</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>GitLab</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                                <div className="col-md-11">
                                                                                    <p>Bitbucket internal tools</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>Feature\branch deployment.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>Writing a test case into QA documentation according to task description and design mock- up.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>Aspect test and fixes.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                                <div className="col-md-11">
                                                                    <p>Fixing if necessary.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" ></div>
                                                <div className="col-md-11">
                                                    <p>Accept next additions based on selected management framework.</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                <div className="col-md-11">
                                                    <p>In case of Scrum</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>Elaborating, decomposing and estimating tasks during the sprint planning. This process allows Product Owner to make changes for each sprint.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                        <div className="col-md-11">
                                                            <p>Particular attention is paid to grouping tasks in such a way that the Product Owner gets a working project with new functionality at the end of each sprint. Sprints with active development in the new functional and stabilization phases are admissible.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                        <div className="col-md-11">
                                                            <p>It is acceptable to go with active development and stabilization sprints.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                        <div className="col-md-11">
                                                            <p>It is possible for the Product Owner to increase the team size. even if it wasn’t planned at the beginning.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                <div className="col-md-11">
                                                    <p>In case of Kanban</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>Elaborating, decomposing and estimating tasks performed right before working on them.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                        <div className="col-md-11">
                                                            <p>The process&#39; main goal is maximum delivery speed.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                        <div className="col-md-11">
                                                            <p>The Product Owner sets the task&#39;s priority.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                        <div className="col-md-11">
                                                            <p>It is possible for the Product Owner to increase the team size, even if it wasn’t planned at the beginning.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                <div className="col-md-11">
                                                    <p>In case of Waterfall</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                        <div className="col-md-11">
                                                            <p>Elaborating, decomposing and estimating tasks performed during initial phase. Detailed task decomposition and elaboration are highly important from both the Business Analyst  and Team Lead sides. If there is no similar experience, this phase may take significantly more time than in Scrum/Kanban based processes. And at the same time it is not completely accurate</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                        <div className="col-md-11">
                                                            <p>It is impossible to make significant changes until the project is finished. And they will require repeating the initial phase.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                        <div className="col-md-11">
                                                            <p>In order to save time, tasks are grouped according to the development team’s priorities. Thus, feedback during the development is not so important, because it&#39;s impossible to make changes.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                        <div className="col-md-11">
                                                            <p>It is difficult for the Product Owner to increase the team size if it wasn’t planned at the beginning because the work schedule and estimates are already done.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                <div className="col-md-11">
                                                    <p>At the end of day…solved problem is more important than any approach or pattern</p>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>
                                    <Accordion className="form-group service-head">
                                        <Accordion.Title
                                            active={activeIndex === 8}
                                            index={8}
                                            onClick={this.handleClick}
                                        >
                                            <div style={{ margin: 0 }} className="h5 font-weight-bold form-group">Testing &amp; Stabilization<ReactSVG src={activeIndex === 8 ? arrowUp : arrowDown} className="float-right" /></div>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 8} className="service-body">
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" ></div>
                                                <div className="col-md-11">
                                                    <p>Working with specifications and documentation - the sooner a bug is detected, the lower the cost of fixing it:</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                <div className="col-md-11">
                                                    <p>GitLab</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>specification testing</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>prototype testing</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                <div className="col-md-11">
                                                    <p>Development of a testing plan</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                <div className="col-md-11">
                                                    <p>Development of initial test cases</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                <div className="col-md-11">
                                                    <p>Working within stories/tasks - identifying bugs in an isolated feature/branch context, allows the development team to quickly determine the cause</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>task description analysis</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>analysis of the completeness and clarity of the task</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>acceptance criteria analysis</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>discussion of the problem with the Business Analyst and correcting</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>preparation of test cases and checklists</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>deployment of the task on dev environment</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>testing in accordance with a test case</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>design testing by QA</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>layout testing by UI designer</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>if a bug is detected, the task is returned to the developer with a detailed description</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>playback steps</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>expected result</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                                                <div className="col-md-11">
                                                                    <p>actual result</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                                                <div className="col-md-11">
                                                                    <p>information about the environment</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                                <div className="col-md-11">
                                                                    <p>additional information</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>in case of success, the task is moved to done</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>implementation of end to end tests and additional automatic tests in accordance with the requirements based on documentation</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                                <div className="col-md-11">
                                                    <p>Automatic tests run automatically on build servers and developer local environments. The code can be merged only after passing all tests:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>code analysis</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>unit test</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>integration test</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>auto test</p>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                                                <div className="col-md-11">
                                                                    <p>end to end</p>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 10 }}>
                                                                <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                                                <div className="col-md-11">
                                                                    <p>API</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                                <div className="col-md-11">
                                                    <p>Regression testing is carried out within the completion of the sprint, or a specific area of ​​work. The main goal is to make sure that there are no hidden bugs, bugs at the junction of the tasks or bugs arising due to side effects of implemented tasks. Facts about regression testing:</p>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>performed on QA environment</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>there is a full manual testing of the build in accordance with documentation</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginTop: 10 }}>
                                                        <div className="col-md-1 d-flex justify-content-end" >:-</div>
                                                        <div className="col-md-11">
                                                            <p>if a bug is detected, it creates an in task tracking system.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion>


                                </Tab.Pane>,
                            },
                            {
                                menuItem: 'Optimization',
                                render: () => <Tab.Pane attached={false}>
                                    <div className="h5 font-weight-bold form-group" style={{ marginTop: 30 }}>The TE Approach</div>
                                    <p>TE’s solutions will help your company’s processes and organizational network to become more efficient, functional and responsive.</p>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >1.</div>
                                        <div className="col-md-11">
                                            <p>Starting from the first stages of the project, each team member works on the product’s improvement.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >2.</div>
                                        <div className="col-md-11">
                                            <p>Ideas are filtered and processed on the basis of business analysis and technical management, after that they are offered to the client.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >3.</div>
                                        <div className="col-md-11">
                                            <p>Continuous improvement of processes on a project helps to increase development speed, improve quality and minimize costs.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >4.</div>
                                        <div className="col-md-11">
                                            <p>The Business Analysts&#39; team research new trends and technical solutions in the domain for suggestions on improvements to the product in accordance with the market changes.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >5.</div>
                                        <div className="col-md-11">
                                            <p>Improving product components by technical experts.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >6.</div>
                                        <div className="col-md-11">
                                            <p>Continuous monitoring of the system&#39;s state. It helps prevent crisis situations and collect data for analysis.</p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >7.</div>
                                        <div className="col-md-11">
                                            <p>Data collection for analytics:</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >a)</div>
                                                <div className="col-md-11">
                                                    <p>the receiving of bug reports from real users</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >b)</div>
                                                <div className="col-md-11">
                                                    <p>the usage of vendors&#39; analytical systems, like google analytics</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >c)</div>
                                                <div className="col-md-11">
                                                    <p>the receiving of logs</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >d)</div>
                                                <div className="col-md-11">
                                                    <p>the receiving of dynamic data from databases</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >e)</div>
                                                <div className="col-md-11">
                                                    <p>the receiving of users feedback</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >f)</div>
                                                <div className="col-md-11">
                                                    <p>the usage of domain-specific methods</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >8.</div>
                                        <div className="col-md-11">
                                            <p>Primary processing and data visualization:</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >a)</div>
                                                <div className="col-md-11">
                                                    <p>development of tools for data analysis within the admin panel</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >b)</div>
                                                <div className="col-md-11">
                                                    <p>logs analysis</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >c)</div>
                                                <div className="col-md-11">
                                                    <p>user’s feedback analysis</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >d)</div>
                                                <div className="col-md-11">
                                                    <p>sage of domain-specific methods</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10 }}>
                                        <div className="col-md-1 d-flex justify-content-end" >9.</div>
                                        <div className="col-md-11">
                                            <p>Creation of hypotheses based on Big Data:</p>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >a)</div>
                                                <div className="col-md-11">
                                                    <p>building of classical mathematical models</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >b)</div>
                                                <div className="col-md-11">
                                                    <p>building of heuristic models</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >c)</div>
                                                <div className="col-md-11">
                                                    <p>analysis of data using AI</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >d)</div>
                                                <div className="col-md-11">
                                                    <p>reviews analysis</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >e)</div>
                                                <div className="col-md-11">
                                                    <p>working with reviews</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >f)</div>
                                                <div className="col-md-11">
                                                    <p>making hypotheses</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >g)</div>
                                                <div className="col-md-11">
                                                    <p>development of requirements for the hypotheses implementation</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >h)</div>
                                                <div className="col-md-11">
                                                    <p>prototypes development</p>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 10 }}>
                                                <div className="col-md-1 d-flex justify-content-end" >i)</div>
                                                <div className="col-md-11">
                                                    <p>usage of domain-specific methods</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>,
                            },
                        ]
                    } />
                </div>
            </section>
        </>);
    }
}

export default Service;
