import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Contacts from './component/Contacts';
import Home from './component/Home';
import Service from './component/Service';
import Thankyou from './component/Thankyou';
function App() {
  return (
    <>
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/Service" component={Service} />
        <Route path="/Contacts" component={Contacts} />
        <Route path="/thank-you" component={Thankyou} />
      </Router>
    </>
  );
}

export default App;
