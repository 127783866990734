import React, { Component } from 'react';
import Thank from "./images/Thank-you.png"

class Thankyou extends Component {
    render() {
        return (
            <>
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: "100vh" }}>
                        <img alt="thank you ship management software" width={400} className="animate-fly" src={Thank} />
                        <h3 className="h3 text-center form-group">Thank you for the Request!</h3>
                        <h6 className="text-center form-group h6" style={{ lineHeight: 1.5 }}>Brutus is on his way to deliver your message.<br />Our team members will contact you shortly!</h6>
                        <a className="font-weight-bold btn btn-primary form-group" href="/">Let's Go Home</a>
                        <p className="text-black-50">© Copyright primemarine.com  @2021 all rights reserved</p>
                    </div>
                </div>
            </>
        );
    }
}

export default Thankyou;