import React, { Component } from 'react';
import Menu from './svg/menu.svg'
import { ReactSVG } from 'react-svg';
class RightSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<>
            <div className="right-sidebar d-flex flex-column">
                <div className="align-self-center d-flex align-items-center justify-content-center test contrast" style={{ marginTop: 40 }} >
                    menu&nbsp;&nbsp;<ReactSVG className="menu-button contrast" src={Menu} onClick={this.props.onClick} />
                </div>
            </div>
        </>);
    }
}

export default RightSidebar;